var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[(_vm.loadingAnualReport)?_c('div',[_c('v-card',{attrs:{"width":"100vw","height":"90vh","elevation":"0"}},[_c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","justify":"center","align":"center"}},[_c('v-progress-circular',{attrs:{"width":4,"size":100,"color":"primary","indeterminate":""}})],1),_c('v-col',{attrs:{"cols":"12","justify":"center","align":"center"}},[_c('div',[_vm._v(" "+_vm._s(_vm.statusLoading)+" ")])])],1)],1)],1)],1):(_vm.leadsMktReportIsEmpty)?_c('div',[_c('v-card',{attrs:{"width":"100vw","height":"90vh","elevation":"0"}},[_c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","justify":"center","align":"center"}}),_c('v-col',{attrs:{"cols":"12","justify":"center","align":"center"}},[_c('div',[_vm._v(" No se encontraron Leads. ")])])],1)],1)],1)],1):_c('div',[_c('div',{staticClass:"py-5"}),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"px-10",attrs:{"cols":"12","sm":"6"}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[(_vm.zonesList.length > 0)?_c('v-select',{attrs:{"hint":_vm.filter
                      ? `Ordenado por: ${_vm.filter}`
                      : 'Filtro Actual: Todos',"items":_vm.zonesList,"prepend-inner-icon":"mdi-filter-menu-outline","label":"Filtrar por Desarrollo","persistent-hint":"","outlined":"","clearable":"","single-line":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}):_vm._e()],1)],1),_c('v-col',{staticClass:"px-7",attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"hint":_vm.howDidFilter && _vm.howDidFilter.name
                    ? `Filtrado por: ${_vm.howDidFilter.name}`
                    : 'Filtro Actual: Todos',"items":_vm.sourcesList,"item-text":"name","prepend-inner-icon":"mdi-filter-menu-outline","label":"Filtrar por ¿Cómo nos encontró?","persistent-hint":"","return-object":"","outlined":"","clearable":"","single-line":""},model:{value:(_vm.howDidFilter),callback:function ($$v) {_vm.howDidFilter=$$v},expression:"howDidFilter"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{attrs:{"elevation":"0","color":"primary"},on:{"click":function($event){return _vm.clearAll()}}},[_vm._v(" Limpiar filtros ")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"my-10",attrs:{"elevation":"0","flat":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":"","headers":_vm.dataTableInfoAll.headers,"items":_vm.dataTableInfoAll.desserts,"items-per-page":100},scopedSlots:_vm._u([{key:"body",fn:function(props){return _vm._l((props.items),function(row,r){return _c('tr',{key:r,class:`${row.color} ${row.txtcolor}`},_vm._l((props.headers),function(column,c){return _c('td',{key:c,staticClass:"pa-2"},[(c === 0)?_c('div',[_vm._v(" "+_vm._s(row.name)+" ")]):(column.text !== 'Total')?_c('div',_vm._l((_vm.getTotalByValueNewv2(
                          row.get,
                          column.value,
                          row.phase
                        )),function(data,i){return _c('div',{key:i},[(row.get === '=')?_c('div',[(row.phase === 'all')?_c('span',{class:data.total === 0 ? 'grey--text' : ''},[_vm._v(" "+_vm._s(data.total)+" ")]):_c('span',{class:data.total === 0 ? 'grey--text' : 'total-info',on:{"click":function($event){data.total === 0
                                ? () => {}
                                : _vm.getLeads({
                                    year: column.value,

                                    phase: row.phase
                                  })}}},[_vm._v(" "+_vm._s(data.total)+" "),_c('small',[_vm._v(" ("+_vm._s(data.avg)+"%)")])])]):_c('div',[_c('div',[_vm._v("$"+_vm._s(_vm.getThreeNumbers(data.mxm))+" MXN")]),_c('div',[_vm._v("$"+_vm._s(_vm.getThreeNumbers(data.usd))+" USD")])])])}),0):_c('div',_vm._l((_vm.getTotalAllNewv2(
                          row.get,
                          row.phase
                        )),function(data,i){return _c('div',{key:i},[(row.get === '=')?_c('div',[(row.phase === 'all')?_c('span',{class:data.total === 0 ? 'grey--text' : ''},[_vm._v(" "+_vm._s(data.total)+" ")]):_c('span',{class:data.total === 0 ? 'grey--text' : ''},[_vm._v(" "+_vm._s(data.total)+" "),_c('small',[_vm._v(" ("+_vm._s(data.avg)+"%)")])])]):_c('div',[_c('div',[_vm._v("$"+_vm._s(_vm.getThreeNumbers(data.mxm))+" MXN")]),_c('div',[_vm._v("$"+_vm._s(_vm.getThreeNumbers(data.usd))+" USD")])])])}),0)])}),0)})}}])})],1)],1)],1)],1)],1),_c('ListLeadsByReportDetail',{attrs:{"leads":_vm.leads,"dialog":_vm.dialog,"loading":_vm.loadingLeadList},on:{"setDialog":value => (_vm.dialog = value)}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }